import React, { Fragment } from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import { Popover, Transition } from '@headlessui/react';
import { StaticImage, getSrc } from 'gatsby-plugin-image';
import Section from '../components/Section';
import Testimonials from '../components/Testimonials3';

import { MenuIcon, XIcon } from '@heroicons/react/outline';
import logoSvgBlue from '../images/tellspin-logo-blue.svg';

import SlackButton from '../components/SlackButton';
import Calendar from '../components/Calendar';
import Seo from '../components/seo';

const footerNavigation = {
  solutions: [
    { name: 'Features', href: '/features/' },
    /*
    { name: 'On Call Shifts', href: '/on-call-shifts/' },
    { name: 'Tasks rotation', href: '/tasks-rotation/' },
    { name: 'Assign reviews', href: '/code-reviewers/' },
  */
  ],
  support: [
    { name: 'Pricing', href: '/pricing/' },
    /*    { name: 'Documentation', href: '/docs/' },*/
    { name: 'Contact', href: '/contact-us/' },
    /*{ name: 'Guides', href: '/guides/' },*/
  ],
  company: [{ name: 'About', href: '/about/' }],
  legal: [
    { name: 'DPA', href: '/dpa/' },
    { name: 'Privacy', href: '/privacy/' },
    { name: 'Terms', href: '/terms-of-use/' },
  ],
  social: [
    {
      name: 'Twitter',
      href: 'https://twitter.com/DOaThingApp',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
        </svg>
      ),
    },
  ],
};

function Quote({ quote, name, title, img }) {
  return (
    <div className="px-4 py-1 border-t border-gray-200 sm:px-6 md:flex md:flex-col md:py-1 md:pl-0">
      <div className="mt-6 md:flex-grow md:flex md:flex-col">
        <div className="relative text-lg font-medium text-gray-700 md:flex-grow">
          <svg
            className="absolute top-0 left-0 invisible w-8 h-8 md:visible text-tellspin transform -translate-x-8 xl:-translate-x-10 -translate-y-2"
            fill="currentColor"
            viewBox="0 0 32 32"
            aria-hidden="true"
          >
            <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
          </svg>
          <p className="mb-0 text-base text-gray-700">{quote}</p>
        </div>
        <footer className="mt-0">
          <div className="flex items-start">
            <div className="inline-flex flex-shrink-0 border-2 rounded-full border-tellspin">
              <img
                className="w-12 h-12 rounded-full"
                src={getSrc(img)}
                alt={name}
              />
            </div>
            <div className="ml-4">
              <div className="text-base font-medium text-gray-700">{name}</div>
              <div className="text-base font-medium text-black">{title}</div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
}

const navigation = [
  { name: 'Features', href: '/features/' },
  { name: 'Pricing', href: '/pricing/' },
  { name: 'About', href: '/about/' },
];

export default function Example({ location }) {
  const { image, dan, jess, sam } = useStaticQuery(
    graphql`
      query {
        image: file(name: { eq: "calendar-full" }) {
          childImageSharp {
            gatsbyImageData(layout: FIXED)
          }
        }
        sam: file(name: { eq: "sam" }) {
          childImageSharp {
            gatsbyImageData(layout: FIXED, width: 100)
          }
        }
        dan: file(name: { eq: "dan" }) {
          childImageSharp {
            gatsbyImageData(layout: FIXED, width: 100)
          }
        }
        jess: file(name: { eq: "jess-engel" }) {
          childImageSharp {
            gatsbyImageData(layout: FIXED, width: 100)
          }
        }
        jeremy: file(name: { eq: "jeremy-engle" }) {
          childImageSharp {
            gatsbyImageData(layout: FIXED, width: 100)
          }
        }
      }
    `,
  );

  return (
    <div className="bg-white">
      <Seo
        title="Continuous improvement tracker in Slack."
        description="Build momentum by tracking atomic habits with your team. Meet people by joining in easily. Set reminders to get started and build consistency by tracking progress. Inspire each other by sharing wins."
        imageSource={getSrc(image)}
        keywords={['standup alternative']}
      />

      <div className="relative overflow-hidden bg-white">
        <div className="mx-auto max-w-7xl">
          <div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-xl xl:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
            <svg
              className="absolute inset-y-0 right-0 hidden w-48 h-full text-white lg:block transform translate-x-1/2"
              fill="currentColor"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
              aria-hidden="true"
            >
              <polygon points="50,0 100,0 50,100 0,100" />
            </svg>

            <Popover>
              {({ open }) => (
                <>
                  <div className="relative px-4 pt-6 sm:px-6 lg:px-8">
                    <nav
                      className="relative flex items-center justify-between sm:h-10 lg:justify-start"
                      aria-label="Global"
                    >
                      <div className="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
                        <div className="flex items-center justify-between w-full md:w-auto">
                          <Link className="flex items-center" to="/">
                            <img
                              className="w-auto h-10 sm:h-9"
                              src={logoSvgBlue}
                              alt="Do a thing logo"
                            />
                            <div className="ml-2 text-xl text-tellspin">
                              DOaThing
                            </div>
                          </Link>
                          <div className="flex items-center -mr-2 md:hidden">
                            <Popover.Button className="inline-flex items-center justify-center p-2 text-gray-400 bg-white rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-tellspin">
                              <span className="sr-only">Open main menu</span>
                              <MenuIcon
                                className="w-6 h-6"
                                aria-hidden="true"
                              />
                            </Popover.Button>
                          </div>
                        </div>
                      </div>
                      <div className="hidden md:block md:ml-10 md:pr-4 md:space-x-8">
                        {navigation.map((item) => (
                          <Link
                            key={item.name}
                            to={item.href}
                            className="font-medium text-gray-500 hover:text-gray-900"
                          >
                            {item.name}
                          </Link>
                        ))}
                      </div>
                    </nav>
                  </div>

                  <Transition
                    show={open}
                    as={Fragment}
                    enter="duration-150 ease-out"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="duration-100 ease-in"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <Popover.Panel
                      focus
                      static
                      className="absolute inset-x-0 top-0 p-2 transition transform origin-top-right md:hidden"
                    >
                      <div className="overflow-hidden bg-white rounded-lg shadow-md ring-1 ring-black ring-opacity-5">
                        <div className="flex items-center justify-between px-5 pt-4">
                          <Link className="flex items-center" to="/">
                            <img
                              className="w-auto h-10 sm:h-9"
                              src={logoSvgBlue}
                              alt="Do a thing logo"
                            />
                            <div className="ml-2 text-xl text-tellspin">
                              DOaThing
                            </div>
                          </Link>
                          <div className="-mr-2">
                            <Popover.Button className="inline-flex items-center justify-center p-2 text-gray-400 bg-white rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-tellspin">
                              <span className="sr-only">Close main menu</span>
                              <XIcon className="w-6 h-6" aria-hidden="true" />
                            </Popover.Button>
                          </div>
                        </div>
                        <div className="px-2 pt-2 pb-3 space-y-1">
                          {navigation.map((item) => (
                            <Link
                              key={item.name}
                              to={item.href}
                              className="block px-3 py-2 text-base font-medium text-gray-700 rounded-md hover:text-gray-900 hover:bg-gray-50"
                            >
                              {item.name}
                            </Link>
                          ))}
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover>

            <main className="px-4 mx-auto mt-10 max-w-7xl sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
              <div className="sm:text-center lg:text-left">
                <h1 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl md:text-6xl">
                  <span className="block xl:inline">Build momentum!</span>{' '}
                  <span className="block text-tellspin xl:inline">
                    DO a Thing
                  </span>{' '}
                  daily
                </h1>
                <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto lg:mr-3 md:mt-5 md:text-xl lg:mx-0">
                  Inspire others to get started or keeping going by doing your
                  thing
                </p>
                <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                  <SlackButton funnel="index" />
                </div>
              </div>
            </main>
          </div>
        </div>
        <div className="pt-10 pb-10 lg:pt-0 lg:pb-0 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 bg-tellspin">
          <div>
            <Calendar />
          </div>
        </div>
      </div>

      <main>
        <div className="relative pt-10 pb-20 md:pt-16 md:pb-32 ">
          <div
            aria-hidden="true"
            className="absolute inset-x-0 top-0 h-48 bg-gradient-to-b from-gray-100"
          />
          <div className="relative px-4 lg:mx-auto lg:max-w-7xl lg:px-8 ">
            <Section>
              <div className="max-w-xl px-4 mx-auto lg:max-w-none lg:mx-0 lg:px-0">
                <div>
                  <h2 className="mt-0 text-4xl font-extrabold tracking-tight text-gray-900">
                    Don't break the chain together
                  </h2>
                  <p className="mt-4 text-lg text-gray-700">
                    Join others doing things across the whole company. Keep each
                    other motivated by celebrating daily progress and wins.
                  </p>
                </div>
                <Quote
                  quote="One of the biggest things I believe in are power hours and outbound outreach. The time of day doesn't matter AS Much to me as much as DOING it does. Consistency trumps all"
                  name="Sam Mckay "
                  img={sam}
                  title="Sales Leader · Motivosity"
                />
              </div>
              <div className="mt-5 lg:mt-0 ">
                <div className="max-w-xl px-4 mx-auto lg:max-w-none lg:mx-0 lg:px-0 ">
                  <StaticImage
                    src="../images/calendar-full.png"
                    className="shadow-xl rounded-xl ring-1 ring-black ring-opacity-5 "
                    formats={['AUTO', 'WEBP', 'AVIF']}
                    alt="Rotation schedule in Slack"
                    layout="fullWidth"
                  />
                </div>
              </div>
            </Section>
            <Section>
              <div className="order-1 max-w-xl px-4 mx-auto mt-10 lg:max-w-none lg:mx-0 lg:px-0 md:order-2">
                <div>
                  <h2 className="mt-0 text-4xl font-extrabold tracking-tight text-gray-900">
                    Continuous improvement culture
                  </h2>
                  <p className="mt-4">
                    Improve by 1% each day by tracking it together. Achieve “the
                    aggregation of marginal gains” by doing simple things.
                    Choose a daily, weekly, or custom interval whatever works
                    best.{' '}
                  </p>
                </div>

                <Quote
                  quote="I always wanted to floss every day. Now that my co-workers can see if I did or not, it actually is really motivating. Sometimes I even consider lying, but then I just go do it instead"
                  name="Dan Willoughby"
                  img={dan}
                  title="Senior Software Engineer · Google User"
                />
              </div>
              <div className="mt-5 lg:mt-0 col-start-none md:col-start-1">
                <div className="max-w-xl px-4 mx-auto lg:max-w-none lg:mx-0 lg:px-0 ">
                  <StaticImage
                    src="../images/slash-2.png"
                    className="shadow-xl rounded-xl ring-1 ring-black ring-opacity-5 "
                    formats={['AUTO', 'WEBP', 'AVIF']}
                    alt="Several user group names"
                    layout="fullWidth"
                  />
                </div>
              </div>
            </Section>
            <Section>
              <div className="max-w-xl px-4 mx-auto mt-10 lg:max-w-none lg:mx-0 lg:px-0">
                <div>
                  <h2 className="mt-0 text-4xl font-extrabold tracking-tight text-gray-900">
                    Get started
                  </h2>
                  <p className="mt-4">
                    Reminders for regular check-ins (or an extra nudge) helps
                    build consistency. Relationships are built when you
                    accomplish things together.
                  </p>
                </div>
                <Quote
                  quote="I'm actually pretty absent minded when it comes to making new habits. Joining a cohort of colleagues has finally helped me get started"
                  name="Dan Willoughby"
                  img={dan}
                  title="Director of Engineering"
                />
              </div>
              <div className="mt-5 lg:mt-0 ">
                <div className="max-w-xl px-4 mx-auto lg:max-w-none lg:mx-0 lg:px-0 ">
                  <StaticImage
                    src="../images/reminder-3.png"
                    className="shadow-xl rounded-xl ring-1 ring-black ring-opacity-5 "
                    formats={['AUTO', 'WEBP', 'AVIF']}
                    alt="Select users for rotation"
                    layout="fullWidth"
                  />
                </div>
              </div>
            </Section>
          </div>
        </div>
        <Testimonials />

        {/* CTA Section */}
        <div className="my-1 bg-white md:my-10">
          <div className="max-w-4xl px-4 py-16 mx-auto sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8 lg:flex lg:items-center lg:justify-between">
            <h2 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              <span className="block">Ready to get started?</span>
              <span className="block text-transparent text-tellspin bg-clip-text">
                Start your 14-day free trial
              </span>
            </h2>
            <div className="mt-6 space-y-4 sm:space-y-0 sm:flex sm:space-x-5">
              <SlackButton funnel="index-2" />
            </div>
          </div>
        </div>
      </main>

      <footer className="bg-gray-50" aria-labelledby="footer-heading">
        <h2 id="footer-heading" className="sr-only">
          Footer
        </h2>
        <div className="px-4 pt-16 pb-8 mx-auto max-w-7xl sm:px-6 lg:pt-24 lg:px-8">
          <div className="xl:grid xl:grid-cols-2 xl:gap-8">
            <div className="grid md:grid-cols-4 grid-cols-2 gap-8 xl:col-span-2">
              <div>
                <h3 className="text-sm font-semibold tracking-wider uppercase text-tellspin">
                  Solutions
                </h3>
                <ul className="mt-4 space-y-4">
                  {footerNavigation.solutions.map((item) => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        className="text-base text-gray-500 hover:text-gray-900"
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="md:mt-0">
                <h3 className="text-sm font-semibold tracking-wider uppercase text-tellspin">
                  Support
                </h3>
                <ul className="mt-4 space-y-4">
                  {footerNavigation.support.map((item) => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        className="text-base text-gray-500 hover:text-gray-900"
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div>
                <h3 className="text-sm font-semibold tracking-wider uppercase text-tellspin">
                  Company
                </h3>
                <ul className="mt-4 space-y-4">
                  {footerNavigation.company.map((item) => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        className="text-base text-gray-500 hover:text-gray-900"
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="md:mt-0">
                <h3 className="text-sm font-semibold tracking-wider uppercase text-tellspin">
                  Legal
                </h3>
                <ul className="mt-4 space-y-4">
                  {footerNavigation.legal.map((item) => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        className="text-base text-gray-500 hover:text-gray-900"
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="pt-8 mt-12 border-t border-gray-200 md:flex md:items-center md:justify-between lg:mt-16">
            <div className="flex space-x-6 md:order-2">
              {footerNavigation.social.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className="text-gray-400 hover:text-gray-500"
                >
                  <span className="sr-only">{item.name}</span>
                  <item.icon className="w-6 h-6" aria-hidden="true" />
                </a>
              ))}
            </div>
            <div>
              <div className="mt-3">
                <a
                  className="text-lg text-tellspin"
                  href="https://plainice.com"
                >
                  <strong>Plainice</strong>
                </a>{' '}
              </div>
              <div>Cool refreshing software</div>
              {'Copyright © '}
              {new Date().getFullYear()}
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
