import React from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  callToAction: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4),
    textAlign: 'center',
  },
  left: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(4),
    marginTop: 0,
    textAlign: 'left',
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(4),
    },
  },
  actionText: {
    marginBottom: theme.spacing(2),
  },
  img: {
    width: 150,
    [theme.breakpoints.up('md')]: {
      width: 170,
    },
  },
  slackButton: {
    '&:hover': {
      backgroundColor: '#fff',
      color: '#3c52b2',
    },
  },
}));

export default function SlackButton({ className, funnel }) {
  const classes = useStyles();
  return (
    <a
      className={classes.slackButton}
      href={`https://slack.com/oauth/v2/authorize?client_id=${process.env.GATSBY_SLACK_CLIENT_ID}&scope=commands,users:read,app_mentions:read,chat:write&state=${funnel}`}
    >
      <img
        alt="Add to Slack"
        className={classNames(classes.img, className)}
        src="https://platform.slack-edge.com/img/add_to_slack@2x.png"
      />
    </a>
  );
}
