import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

export default function Example() {
  return (
    <section className="py-12 overflow-hidden bg-tellspin md:py-20 lg:py-24">
      <div className="relative px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="relative">
          <svg
            className="absolute opacity-50 top-5 left-5 transform -translate-x-8 -translate-y-24 h-36 w-36 text-tellspinYellow"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 144 144"
            aria-hidden="true"
          >
            <path
              strokeWidth={2}
              d="M41.485 15C17.753 31.753 1 59.208 1 89.455c0 24.664 14.891 39.09 32.109 39.09 16.287 0 28.386-13.03 28.386-28.387 0-15.356-10.703-26.524-24.663-26.524-2.792 0-6.515.465-7.446.93 2.327-15.821 17.218-34.435 32.11-43.742L41.485 15zm80.04 0c-23.268 16.753-40.02 44.208-40.02 74.455 0 24.664 14.891 39.09 32.109 39.09 15.822 0 28.386-13.03 28.386-28.387 0-15.356-11.168-26.524-25.129-26.524-2.792 0-6.049.465-6.98.93 2.327-15.821 16.753-34.435 31.644-43.742L121.525 15z"
            />
          </svg>
          <div className="flex items-center justify-center mb-5 text-center md:flex-shrink-0">
            <StaticImage
              src="../images/tellspin-logo-white.svg"
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt="DO a thing logo"
              layout="fixed"
              className="h-12"
              height={50}
            />
            <span className="ml-5 text-3xl font-medium text-white">
              DOaThing
            </span>
          </div>
          <blockquote className="mt-10">
            <div className="max-w-3xl mx-auto text-2xl font-medium text-center text-white leading-9">
              <p>
                Since the pandemic, it's been really hard to not feel stuck in a
                rut. DOaThing helps my teams stop languishing and find their
                peak flow. It builds momentum because others can see that how
                much progress is being made company wide.
              </p>
            </div>
            <footer className="mt-8">
              <div className="mx-auto md:flex md:items-center md:justify-center">
                <div className="md:flex-shrink-0">
                  <StaticImage
                    src="../images/dan.jpeg"
                    formats={['AUTO', 'WEBP', 'AVIF']}
                    alt="Eilin Chang"
                    layout="fixed"
                    className="h-12 mx-auto border-2 border-white rounded-full"
                    height={50}
                  />
                </div>
                <div className="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
                  <div className="text-base text-lg font-medium text-white">
                    Dan Willoughby
                  </div>

                  <svg
                    className="hidden w-5 h-5 mx-1 md:block text-tellspinYellow"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M11 0h3L9 20H6l5-20z" />
                  </svg>

                  <div className="text-base text-lg font-medium text-gray-200">
                    CEO · DOaThing
                  </div>
                </div>
              </div>
            </footer>
          </blockquote>
        </div>
      </div>
    </section>
  );
}
