import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useStaticQuery, graphql } from 'gatsby';
import classNames from 'classnames';
import ChatIcon from '@material-ui/icons/Chat';
import { StaticImage } from 'gatsby-plugin-image';
import { getSrc } from 'gatsby-plugin-image';
import lightbulbYellow from '../images/light-bulb-yellow.svg';

import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  supportContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  calendar: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    width: 300,
    height: 200,
    overflow: 'hidden',
  },
  day: {
    position: 'absolute',
    width: 90,
    marginTop: 40,
    textAlign: 'center',
    margin: 10,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    opacity: 0,
    border: '3px solid white',
    borderRadius: 5,
  },
  dayText: {
    marginBottom: theme.spacing(1),
    width: '100%',
    borderBottom: '2px solid white',
    padding: 6,
    color: 'white',
  },
  arrow: {},
  '@keyframes myEffect': {
    '0%, 25%': {
      transform: 'translate(65px, -190px)',
      opacity: 0,
    },
    '53%': {
      transform: 'translate(65px, -190px)',
      opacity: 1,
    },
    '99%': {
      transform: 'translate(22px, 30px)',
      opacity: 1,
    },
    '100%': {
      transform: 'translate(22px, 30px)',
      opacity: 0,
    },
  },
  '@keyframes move': {
    '0%': {
      transform: 'translate(450px, 0px)',
    },
    '15%': {
      transform: 'translate(340px, 0px)',
      opacity: 0,
    },
    '30%': {
      transform: 'translate(230px, 0px)',
      color: 'white',
      opacity: 0.5,
    },
    '40%': {
      color: 'white',
      borderBottomColor: 'white',
      borderColor: 'white',
      backgroundColor: '#0000',
    },
    '42%': {
      color: theme.palette.secondary.main,
      borderBottomColor: theme.palette.secondary.main,
      backgroundColor: `${theme.palette.secondary.main}11`,
      borderColor: theme.palette.secondary.main,
    },
    '44%': {
      color: 'white',
      borderColor: 'white',
      backgroundColor: '#0000',
      borderBottomColor: 'white',
    },
    '45%': {
      transform: 'translate(110px, 0px)',
      color: 'white',
      opacity: 1,
    },
    '60%': {
      color: 'white',
      borderColor: 'white',
      opacity: 0.5,
      transform: 'translate(-10px, 0px)',
    },
    '75%': {
      transform: 'translate(-120px, 0px)',
      opacity: 0,
    },
    '100%': {
      color: 'grey',
      transform: 'translate(-230px, 0px)',
    },
  },
  '@keyframes shake': {
    '41%, 49%, 59%': {
      transform: 'translate3d(0px, 0, 0)',
    },
    '43%, 51%': {
      transform: 'translate3d(2px, 0, 0)',
    },
    '45%, 53%': {
      transform: 'translate3d(-4px, 0, 0)',
    },
    '47%, 56%': {
      transform: 'translate3d(4px, 0, 0)',
    },
    '40%, 60%': {
      color: 'white',
    },
    '49%, 55%': {
      color: theme.palette.secondary.main,
    },
  },
  animatedItem: {
    animation: `$myEffect 3s ${theme.transitions.easing.easeInOut} infinite`,
    color: 'white',
    position: 'absolute',
    height: 50,
    width: 50,
    zIndex: 1000,
    marginTop: 0,
    [theme.breakpoints.up('md')]: {
      marginTop: 0,
    },
  },
  move: {
    animation: '$move 21s infinite',
  },
  support: {
    marginTop: theme.spacing(8),
    color: 'white',
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(8),
    },
    marginBottom: theme.spacing(2),
    animation: '$shake 3s cubic-bezier(.36,.07,.19,.97) infinite',
  },
}));

export default function Calendar(props) {
  const classes = useStyles();
  const { alex, almos, chris, erik, joe, dan, rob } = useStaticQuery(
    graphql`
      query {
        alex: file(name: { eq: "alex-iby-XhMSz5I1kn8-unsplash" }) {
          childImageSharp {
            gatsbyImageData(layout: FIXED, width: 100)
          }
        }
        almos: file(name: { eq: "almos-bechtold-3402kvtHhOo-unsplash" }) {
          childImageSharp {
            gatsbyImageData(layout: FIXED, width: 100)
          }
        }
        chris: file(name: { eq: "christopher-campbell-rDEOVtE7vOs-unsplash" }) {
          childImageSharp {
            gatsbyImageData(layout: FIXED, width: 100)
          }
        }
        erik: file(name: { eq: "erik-lucatero-d2MSDujJl2g-unsplash" }) {
          childImageSharp {
            gatsbyImageData(layout: FIXED, width: 100)
          }
        }
        joe: file(name: { eq: "joseph-gonzalez-iFgRcqHznqg-unsplash" }) {
          childImageSharp {
            gatsbyImageData(layout: FIXED, width: 100)
          }
        }
        dan: file(name: { eq: "dan" }) {
          childImageSharp {
            gatsbyImageData(layout: FIXED, width: 100)
          }
        }
        rob: file(name: { eq: "robert-godwin-cdksyTqEXzo-unsplash" }) {
          childImageSharp {
            gatsbyImageData(layout: FIXED, width: 100)
          }
        }
      }
    `,
  );

  return (
    <div>
      <div className="max-w-md m-auto mt-10 text-white">
        <StaticImage
          src="../images/slack-did-my-thing.png"
          layout="fullWidth"
          formats={['AUTO', 'WEBP', 'AVIF']}
          quality={95}
          alt="Sending a slack message"
        />
      </div>
      <div className={classes.supportContainer}>
        <div className={classNames({ [classes.animatedItem]: true })}>
          <img
            className="w-auto h-32"
            src={lightbulbYellow}
            alt="Yellow Light bulb"
          />
        </div>
        <div className={classes.calendar}>
          {[
            { day: '9am', name: '@alex', delay: '-6s', img: alex },
            { day: '10am', name: '@rob', delay: '-3s', img: rob },
            { day: '12pm', name: '@sara', delay: '0s', img: chris },
            { day: '1pm', name: '@erik', delay: '3s', img: erik },
            { day: '3pm', name: '@joe', delay: '6s', img: joe },
            { day: '5pm', name: '@dan', delay: '9s', img: dan },
            { day: '8pm', name: '@almos', delay: '12s', img: almos },
          ].map((elem, index) => {
            return (
              <div
                key={elem.day}
                className={classNames(classes.day, classes.move)}
                style={{ animationDelay: elem.delay }}
              >
                <Typography variant="body1" className={classes.dayText}>
                  {elem.day}
                </Typography>
                <div className="flex-shrink-0 inline-flex rounded-lg ">
                  <img
                    className="w-12 h-12 rounded-lg"
                    src={getSrc(elem.img)}
                    alt={elem.name}
                  />
                </div>
                <div className="items-center p-1">
                  <Typography variant="body1">{elem.name}</Typography>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
